<template>
  <div v-if="emailAccount" class="email-account-edit-page">
    <vuestic-widget :loading="fetching">
      <template slot="header">
        <span class="title">Mail Provider #{{emailAccount['id']}}</span>
      </template>
      <div class="smtp-provider-wrapper">
        <ValidationObserver v-slot="{ handleSubmit, invalid, dirty }" ref="smptForm">
          <form @submit.prevent="handleSubmit(save)">
            <div class="row">
              <div class="col-md-12 mb-3">
                <h5 class="text-primary font-weight-bold">Account Setting</h5>
              </div>
              <div class="col-md-12 mb-3">
                <a href="javascript:;" class="text-primary font-weight-bold" @click="isOpenModalInstruction = true">( Before You Setup - Please Check This Guide ! )</a>
              </div>
              <div class="col-md-6">
                <text-input type="email" name="email" v-model="email" label="Email*" validate="required|email"/>
              </div>
              <div class="col-md-6">
                <text-input label="Sender Name*" v-model="sender" name="sender" :validate="'required'"></text-input>
              </div>
              <div class="col-md-6">
                <p class="font-weight-bold">Receiving Emails</p>
                <text-input label="IMAP Host" v-model="data.imap_host" name="imap_host"></text-input>
                <text-input label="IMAP Port" v-model="data.imap_port" name="imap_port"></text-input>
                <div class="form-group password-wrapper mt-4">
                  <label class="control-label">Password</label>
                  <div v-if="!showImapPassword" class="api-key-span w-100">{{ imap_password }}</div>
                  <div v-else class="w-100"><text-input type="password" v-model="imap_password_new" name="imap_password"></text-input></div>
                  <span class="btn btn-primary btn-sm ml-2" @click="showImapPassword = !showImapPassword; imap_password_new = ''">{{ showImapPassword ? 'Cancel' : 'Change' }}</span>
                </div>
                <div class="control-label mt-2 mb-2">Enable SSL</div>
                <vuestic-switch v-model="data.imap_ssl" :offcolor=true style="width:100px">
                  <span slot="trueTitle">On</span>
                  <span slot="falseTitle">Off</span>
                </vuestic-switch>
              </div>
              <div class="col-md-6">
                <p class="font-weight-bold">Sending Emails</p>
                <text-input label="SMTP Host" v-model="data.smtp_host" name="smtp_host"></text-input>
                <text-input label="SMTP Port" v-model="data.smtp_port" name="smtp_port"></text-input>
                <div class="form-group password-wrapper mt-4">
                  <label class="control-label">Password</label>
                  <div v-if="!showSmtpPassword"  class="api-key-span w-100">{{ smtp_password }}</div>
                  <div v-else class="w-100"><text-input type="password" v-model="smtp_password_new" name="smtp_password"></text-input></div>
                  <span class="btn btn-primary btn-sm ml-2" @click="showSmtpPassword = !showSmtpPassword; smtp_password_new=''">{{ showSmtpPassword ? 'Cancel' : 'Change' }}</span>
                </div>
                <div class="control-label mt-2 mb-2">Enable SSL</div>
                <vuestic-switch v-model="data.smtp_ssl" :offcolor=true style="width:100px">
                  <span slot="trueTitle">On</span>
                  <span slot="falseTitle">Off</span>
                </vuestic-switch>
              </div>
            </div>
            <div class="row mt-5">
              <div class="col-md-12 mb-4">
                <h5 class="text-primary font-weight-bold">Signature</h5>
              </div>
              <div class="col-md-12">
                <TextEditorPicker
                  :show-bottom="false"
                  v-model="signature"
                  :height="200"
                  ref="texteditor"
                />
              </div>
            </div>
            <div class="row mt-5">
              <div class="col-md-12 mb-4">
                <h5 class="text-primary font-weight-bold">Opt-out Text</h5>
                <p>Specify your opt-out text at the bottom of the email. Make it sound friendly and personal.</p>
              </div>
              <div class="col-md-12">
                <text-input label="Opt-out Text" v-model="optout_text" name="optout_text"></text-input>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-md-12">
                <button class="btn btn-primary" :disabled="invalid" style="min-width: 80px">
                  <atom-spinner
                    v-if="processing"
                    slot="loading"
                    :animation-duration="1500"
                    :size="14"
                    color="#FFF"
                    style="margin: auto"
                  />
                  <span v-else>Update</span>
                </button>
              </div>
            </div>
          </form>
        </ValidationObserver>
      </div>
    </vuestic-widget>
    <vuestic-modal
      :isOpen="isOpenModalInstruction"
      :cancelShown="false"
      @close="isOpenModalInstruction = false"
      okText="Got It !"
    >
      <span slot="title" class="text-primary"><b>Setup Email Account</b></span>
      <br/>To use your email account as servie provicer, you will need to complete the form with following:
      <br/>
      <br/><span class="text-primary font-weight-bold">Default values for google account:</span>
      <br/>
      <br/>
      <table>
        <tr>
          <th style="width: 200px"><b>Field</b></th>
          <th><b>Value</b></th>
        </tr>
        <tr>
          <td>IMAP Host</td>
          <td>imap.gmail.com</td>
        </tr>
        <tr>
          <td>IMAP Port</td>
          <td>993</td>
        </tr>
        <tr>
          <td>SMTP Host</td>
          <td>smtp.gmail.com</td>
        </tr>
        <tr>
          <td>SMTP Port</td>
          <td>465</td>
        </tr>
      </table>
      <br/>
      <ul class="pl-3">
        <li>
          <b>IF Your Google Account doesn't use 2-Step Verification</b>
          <br/> Please allow Less Secure App access <a class="link font-weight-bold" href="https://myaccount.google.com/lesssecureapps">here</a>
        </li>
        <li class="mt-2">
          <b>IF Your Google Account uses 2-Step Verification</b>
          <br/> Please generate an app password <a class="link font-weight-bold" href="https://myaccount.google.com/apppasswords">here</a>
        </li>
      </ul>
      <br/>
      <p><i>Please contact <a class="link" :href="`mailto:${agencyEmail}`">{{ agencyEmail }}</a> if you have further questions.</i></p>
    </vuestic-modal>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import TextEditorPicker from "../../../common/TextEditorPicker";

export default {
  components: {
    TextEditorPicker,
  },
  data() {
    return {
      showImapPassword: false,
      showSmtpPassword: false,
      emailAccount: null,
      email: '',
      sender: '',
      signature: '',
      optout_text: '',
      data: {
        imap_ssl: true,
        smtp_ssl: true,
      },
      imap_password: '',
      imap_password_new: '',
      smtp_password: '',
      smtp_password_new: '',
      loading: false,
      isOpenModalInstruction: false,
    };
  },
  mounted() {
    if (this.$route.params.id) {
      const id = this.$route.params.id
      this.loadData(id);
    } else {
      this.redirectOnError()
    }
  },
  computed: {
    user() {
      return this.$store.state.auth.user;
    },

    agencyEmail() {
      return (this.user && this.user.agency && this.user.agency.email) || "";
    },

    ...mapState('businessIntegration', {
      fetching: 'fetching',
      didFetch: 'didFetch',
      processing: 'processing',
    }),
  },
  methods: {
    redirectOnError() {
      this.$router.push({ name: 'business.setting' });
    },

    loadData(id) {
      this.loading = true;
      this.$store
        .dispatch('businessIntegration/emailAccountGet', id)
        .then((res) => {
          this.loading = false;
          this.emailAccount = res
          this.email = res.email
          this.sender = res.sender
          this.signature = res.signature
          this.optout_text = res.optout_text
          this.data = res.data
          this.imap_password = res.imap_password
          this.smtp_password = res.smtp_password
        })
        .catch((err) => {
          this.loading = false;
        })
    },

    save() {
      const params = {
        id: this.emailAccount.id,
        data: {
          type: 0,
          email: this.email,
          sender: this.sender,
          signature: this.signature,
          optout_text: this.optout_text,
          data: this.data,
          imap_password: this.imap_password_new,
          smtp_password: this.smtp_password_new,
          updateImapPassword: this.showImapPassword,
          updateSmtpPassword: this.showSmtpPassword,
        }
      }

      this.$store
        .dispatch("businessIntegration/emailAccountUpdate", params)
        .then(() => {
          this.$router.push({ name: 'business.setting' })
        })
        .catch((err) => {
        });
    },
  },
};
</script>
<style lang="scss" scoped>
  .smtp-provider-wrapper {
    max-width: 1024px;
    .api-key-span {
      font-size: 17px;
      color: #333333;
      display: block;
      padding: 10px 0 0;
      border-bottom: 1px dashed #666;
    }
  }
</style>

<style lang="scss">
  .smtp-provider-wrapper {
    div.control-label {
      font-size: 0.6rem;
      color: #333;
      font-weight: 600;
      text-transform: uppercase;
    }
    .widget-body {
      padding: 0;
    }
    .password-wrapper {
      .form-group {
        margin-bottom: 0;
      }
    }
  }
</style>